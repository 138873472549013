import numeral from 'numeral';
import numeralfr from 'numeral/locales/fr';

export function formatNumber(number, append = "€") {
    numeral.locale('fr');
    return numeral(number).format('0,0.00') + ` ${append}`;
}

export function extractValueFromItem(item, property) {
    let value = null;
    
    if(typeof property === "string") {
        let splits = property.split(".");

        value = splits.reduce((accumulator, currentValue, index, arr) => {
            if(accumulator == null || !accumulator.hasOwnProperty(currentValue)) {
                // permet de "break" le reduce
                arr.splice(1);
                return null;
            }
            return accumulator[currentValue]
        }, item)
    }

    else if(typeof property === "function"){
        value = property(item);
    }

    return value;
}

export function filterArray(array, criterias, term) {
    let matches = array.filter(item => {
        return itemMatch(item, criterias, term);
    });
    return matches;
}

export function itemMatch(item, criterias, term) {
    let match = true;      
    let currentTerm = "";            
    if(term != "") {

        currentTerm = '/*' + term.toUpperCase() + "/*";
        match = criterias.some(property => {
            let value = extractValueFromItem(item, property)
            
            if(value == null)
                return false;
                
            try {
                return value.toString().toUpperCase().match(currentTerm) != null;
            }
            catch(e) {
                console.log(`filter error: ${value}.toString n'existe pas pour ${property}`, e)
                return false;
            }
        })
    };
    return match;
}

export function generateUniqid(prefix = "") {
    return prefix + (new Date().getTime() + Math.floor((Math.random()*10000)+1)).toString(16);
}

/*
// https://stackoverflow.com/a/5624139
hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    })
  
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      red: parseInt(result[1], 16),
      green: parseInt(result[2], 16),
      blue: parseInt(result[3], 16)
    } : null;
  } */


export async function asyncForEach(array, callback) {
    for (const [key, value] of Object.entries(array)) {
        await callback(value, key, array);
    }
}

export function sortObjectsOnAttribute(obj1, obj2, attributeName)
{
    if (typeof obj1 !== 'undefined' && typeof obj2 !== 'undefined' && obj1 !== null && obj2 !== null && obj1.hasOwnProperty(attributeName) && obj2.hasOwnProperty(attributeName))
    {
        return obj1[attributeName] < obj2[attributeName] ? -1 : (obj1[attributeName] > obj2[attributeName] ? 1 : 0)
    }
    return 0;
}

function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    })
  
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      red: parseInt(result[1], 16),
      green: parseInt(result[2], 16),
      blue: parseInt(result[3], 16)
    } : null;
  }

export const getBgColor = function(backgroundColor) {
    if(backgroundColor == null || backgroundColor == "")
        return {backgroundColor: "white", color: ""}
    // https://stackoverflow.com/a/3943023
    let threshold = 140;
    let coefs = {red: 0.299, green: 0.587, blue: 0.114};

    let colors = hexToRgb(backgroundColor)
    let score = 0;
    for (const [key, value] of Object.entries(colors)) {
        score += value * coefs[key];
    }
    let color = score < threshold ? "white" : "";

    return {backgroundColor, color}
}